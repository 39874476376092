export function omit<T extends Record<string, any>, K extends keyof T>(
  object: T,
  keysToOmit: K[] | any[],
): Pick<T, Exclude<keyof T, K>> {
  const result = { ...object }

  for (const key of keysToOmit)
    delete result[key]

  return result
}

export function safeAssign<T, K extends keyof T>(obj: T, key: K, value: any): void {
  obj[key] = value
}

export function addArrayObjectIndex<T extends object>(data: Array<T>) {
  return data.map((i, index) => ({ ...i, index }))
}

// export function sortByAttribute<T extends Record<string, any>>(sortAttribute: keyof T, sortOrder: 'asc' | 'desc' = 'asc') {
//   return (a: T, b: T) => {
//     const aValue = (a[sortAttribute] ?? Number.POSITIVE_INFINITY) as any
//     const bValue = (b[sortAttribute] ?? Number.POSITIVE_INFINITY) as any
//     return sortOrder === 'asc' ? aValue - bValue : bValue - aValue
//   }
// }

export function sortByAttribute<T extends Record<string, any>>(sortAttribute: keyof T, sortOrder: 'asc' | 'desc' = 'asc') {
  return (a: T, b: T) => {
    const aValue = a[sortAttribute] ?? (sortOrder === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY)
    const bValue = b[sortAttribute] ?? (sortOrder === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY)

    return sortOrder === 'asc'
      ? aValue - bValue
      : bValue - aValue
  }
}
export function sortByMultiAttributes<T extends Record<string, any>>(
  sortCriteria: Array<{ attribute: keyof T, order: 'asc' | 'desc' }>,
) {
  return (a: T, b: T) => {
    for (const { attribute, order } of sortCriteria) {
      const aValue = (a[attribute] ?? Number.POSITIVE_INFINITY) as any
      const bValue = (b[attribute] ?? Number.POSITIVE_INFINITY) as any

      if (aValue !== bValue) {
        return order === 'asc' ? aValue - bValue : bValue - aValue
      }
    }
    return 0
  }
}

export function repeatArray(arr: number[], times: number): number[] {
  // eslint-disable-next-line unicorn/no-new-array
  return new Array(times).fill(arr).flat()
}

export function calculateMetricPercentile(sortedArr: number[], value: number): number {
  const rank = sortedArr.findIndex(v => v >= value)
  if (rank === -1)
    return 100
  if (rank === 0)
    return 0
  const lower = sortedArr[rank - 1]
  const upper = sortedArr[rank]
  const proportion = (value - lower) / (upper - lower)
  return roundNumber(((rank - 1) + proportion) / (sortedArr.length - 1), 2)
}

export function roundNumber(num: number, decimalPlaces = 2): number {
  const factor = 10 ** decimalPlaces
  return Math.round(num * factor) / factor
}

export function parsePercMetric(value: string | number | undefined): number {
  if (typeof value === 'undefined')
    return 0
  if (typeof value === 'string')
    return roundNumber(Number.parseFloat(value), 2)
  return roundNumber(value, 2)
}

export function clampNumber(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, value))
}

export function normalizeNumber(value: number, min: number, maax: number): number {
  const clampedValue = clampNumber(value, min, maax)
  return (clampedValue - min) / (maax - min)
}

export function normalizeWithCenter(value: number, min: number, max: number, center: number): number {
  const clampedValue = Math.max(min, Math.min(value, max))
  return clampedValue <= center
    ? (clampedValue - min) / (center - min)
    : 1 - (clampedValue - center) / (max - center)
}

export function iconNormalizedNumber(value?: number): string {
  if (value === undefined || Number.isNaN(value))
    return ''
  if (value <= 0.2)
    return 'i-material-symbols-trending-down'
  if (value <= 0.8)
    return 'i-material-symbols-trending-flat'
  return 'i-material-symbols-trending-up'
}

export function getNumberValue(item?: string | number) {
  if (!item)
    return 0
  return typeof item === 'string' ? Number.parseFloat(item) : item
}

export function getDiffValue(oldValue?: string | number, newValue?: string | number, round: number = 2) {
  return roundNumber(getNumberValue(newValue) - getNumberValue(oldValue), round)
}

export function convertStringToNumber(value: unknown): number | undefined {
  if (typeof value === 'number')
    return value
  if (typeof value === 'string') {
    const numValue = Number(value)
    if (!Number.isNaN(numValue) && Number.isFinite(numValue))
      return numValue
  }
  return undefined
}

export function getOrdinalNumber(inputNumber: number | string): string {
  const numValue = convertStringToNumber(inputNumber)
  if (!numValue)
    return '-'
  const lastDigit = numValue % 10
  const lastTwoDigits = numValue % 100

  if (lastDigit === 1 && lastTwoDigits !== 11)
    return `${numValue}st`
  if (lastDigit === 2 && lastTwoDigits !== 12)
    return `${numValue}nd`
  if (lastDigit === 3 && lastTwoDigits !== 13)
    return `${numValue}rd`

  return `${numValue}th`
}

export function groupByKey<T extends Record<PropertyKey, any>, Key extends Filter<T>>(arr: Array<T>, key: Key): Record<T[Key], Array<T>> {
  return arr.reduce((accumulator, val) => {
    const groupedKey = val[key]
    if (!accumulator[groupedKey])
      accumulator[groupedKey] = []
    accumulator[groupedKey].push(val)
    return accumulator
  }, {} as Record<T[Key], Array<T>>)
}

export function flattenRecordArray<T>(input: Record<string, T[]>): Record<string, T> {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [key, value[0]]),
  )
}

export function extractKeyObjects<T extends Record<PropertyKey, any>, K extends keyof T>(arr: Array<T>, keys: Array<K>): Array<Pick<T, K>> {
  return arr.map((obj) => {
    const result = {} as Pick<T, K>
    for (const key of keys) {
      if (key in obj) {
        result[key] = obj[key]
      }
    }
    return result
  })
}

export function extractUniqueObjectsById<T extends Record<PropertyKey, any>, K extends keyof T>(objects: Array<T>, key: K): Array<T> {
  const uniqueSet = new Set()
  const result: T[] = []
  for (const obj of objects) {
    if (!uniqueSet.has(obj[key])) {
      uniqueSet.add(obj[key])
      result.push(obj)
    }
  }
  return result
}

export function countByKey<T extends Record<PropertyKey, any>, Key extends keyof T>(arr: Array<T>, key: Key): Record<Exclude<T[Key], undefined | null>, number> {
  return arr.reduce((accumulator, val) => {
    const groupedKey = val[key]
    if (groupedKey === undefined || groupedKey === null)
      return accumulator
    if (!accumulator[groupedKey])
      accumulator[groupedKey] = 0
    accumulator[groupedKey]++
    return accumulator
  }, {} as Record<T[Key], number>)
}

export function groupByAttribute<T, K extends keyof T>(data: T[], attribute: K, defaultKey = 'Unknown'): Record<string, T[]> {
  const grouped: Record<string, T[]> = {}
  for (const item of data) {
    const key = item[attribute] as string ?? defaultKey
    if (!grouped[key])
      grouped[key] = []
    grouped[key].push(item)
  }
  return grouped
}

export function normalizeValue(value: number, minValue: number, maxValue: number, reverse = false): number {
  const normalizedValue = Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1)
  return reverse ? 1 - normalizedValue : normalizedValue
}

// export function extractMinMax(values: Array<number>): [number, number] {
//   const sortedValues = values.sort((a, b) => a - b)
//   return [sortedValues[0], sortedValues[sortedValues.length - 1]]
// }

export function extractMinMax(values: number[], lowerPercentile: number = 0, upperPercentile: number = 100): [number, number] {
  if (values.length === 0)
    throw new Error('The input array is empty')
  if (lowerPercentile < 0 || lowerPercentile > 100 || upperPercentile < 0 || upperPercentile > 100 || lowerPercentile >= upperPercentile)
    throw new Error('Invalid percentile values')
  const sortedValues = values.slice().sort((a, b) => a - b)
  const getPercentileIndex = (percentile: number) => Math.floor((percentile / 100) * (sortedValues.length - 1))
  return [
    sortedValues[getPercentileIndex(lowerPercentile)],
    sortedValues[getPercentileIndex(upperPercentile)],
  ]
}

export function getIntValue(value: number | string): number {
  return (typeof value === 'number') ? value : Number.parseInt(value)
}

export function isValueInRange(value: number, range: [number, number]) {
  return value >= range[0] && value <= range[1]
}

export function createInterpolatedMap(
  dataPoints: Array<{ num: number, value: number }>,
  options: { extrapolate?: boolean, precision?: number } = {},
): Map<number, number> {
  const {
    extrapolate = false,
    precision = 1,
  } = options

  const sortedPoints = [...dataPoints].sort((a, b) => a.num - b.num)
  const result = new Map<number, number>()

  function interpolate(x: number, x1: number, y1: number, x2: number, y2: number): number {
    return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1)
  }

  let startIndex = 0
  let endIndex = sortedPoints.length - 1

  if (!extrapolate) {
    result.set(sortedPoints[0].num, sortedPoints[0].value)
    result.set(sortedPoints[endIndex].num, sortedPoints[endIndex].value)
    startIndex = 1
    endIndex = sortedPoints.length - 2
  }

  for (let i = startIndex; i < endIndex; i++) {
    const start = sortedPoints[i]
    const end = sortedPoints[i + 1]

    for (let num = start.num; num < end.num; num++) {
      const value = interpolate(num, start.num, start.value, end.num, end.value)
      result.set(num, roundNumber(value, precision))
    }
  }

  if (endIndex > startIndex) {
    const lastStart = sortedPoints[endIndex]
    const lastEnd = sortedPoints[endIndex + 1] || lastStart
    for (let num = lastStart.num; num <= lastEnd.num; num++) {
      const value = interpolate(num, lastStart.num, lastStart.value, lastEnd.num, lastEnd.value)
      result.set(num, roundNumber(value, precision))
    }
  }

  return result
}
// export function genArrayFromObj<T extends Record<string, any>>(
//   objOfArrays: { [K in keyof T]: Array<T[K]> },
// ): T[] {
//   const keys = Object.keys(objOfArrays) as (keyof T)[]
//   const length = objOfArrays[keys[0]].length
//   return Array.from({ length }).map((_, index) => {
//     const obj: T = {} as T
//     for (const key of keys)
//       obj[key] = objOfArrays[key][index]
//     return obj
//   })
// }

// export function deepMergeFalsy(...objects: any[]) {
//   return _mergeWith({}, ...objects, (objValue: any, srcValue: null): any => {
//     return !_isUndefined(srcValue) && srcValue !== null ? srcValue : objValue
//   })
// }

// export function deepMerge(...objects: any[]): any {
//   return _merge({}, ...objects)
// }

// export function exponentialMovingAverage(dataset: number[], windowSize: number = 5): number[] {
//   const n = dataset.length
//   if (n < windowSize)
//     return []
//   const ema: number[] = [simpleMovingAverage(dataset.slice(0, windowSize))]
//   const multiplier = 2 / (windowSize + 1)
//   for (let i = windowSize; i < n; i++) {
//     const currentEma = ((dataset[i] - ema[i - windowSize]) * multiplier) + ema[i - windowSize]
//     ema.push(currentEma)
//   }
//   return ema
// }

// function simpleMovingAverage(windowData: number[]): number {
//   return windowData.reduce((a, b) => a + b, 0) / windowData.length
// }
