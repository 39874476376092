import chroma from 'chroma-js'
import colors from '#tailwind-config/theme/colors'

const chartColors: iChartColorDefinition[] = [
  { hex: '#b6d7a8', rgb: [182, 215, 168], name: 'green' },
  { hex: '#ffe599', rgb: [255, 229, 153], name: 'yellow' },
  { hex: '#ea9999', rgb: [234, 153, 153], name: 'red' },
  { hex: '#9fa1e8', rgb: [159, 161, 232], name: 'purple' },
  { hex: '#0ea5e9', rgb: [14, 165, 233], name: 'sky' },
  { hex: '#f59e0b', rgb: [245, 158, 11], name: 'amber' },
  { hex: '#10b981', rgb: [16, 185, 129], name: 'emerald' },
  { hex: '#ec4899', rgb: [236, 72, 153], name: 'pink' },
]

// bg-slate-500 #64748b
// bg-red-500 #ea9999
// bg-orange-500  #f97316
// bg-yellow-500 #ffe599
// bg-green-500 #b6d7a8
// bg-emerald-500 #10b981
// bg-sky-500 #0ea5e9
// bg-blue-500 #9fc5e8
// bg-indigo-500 #6366f1
// bg-violet-500 #8167a9
// bg-purple-500 #9fa1e8
// bg-pink-500 #ec4899
// bg-rose-500 #f43f5e

// bg-amber-500 #f59e0b
// bg-sky-500 #0ea5e9
// bg-emerald-500 #10b981
// bg-violet-500 #8167a9
// bg-pink-500 #ec4899
// bg-yellow-500 #ffe599

const colorScale = chroma.scale([colors.purple['500'], colors.green['500'], colors.blue['500'], colors.yellow['500'], colors.amber['500'], colors.red['500']]).mode('lch')

const posColorMap = new Map([
  ['QB', 'bg-green-500'],
  ['RB', 'bg-red-500'],
  ['WR', 'bg-yellow-500'],
  ['TE', 'bg-blue-500'],
  ['PK', 'bg-purple-500'],
  ['K', 'bg-purple-500'],
  ['PK1', 'bg-purple-500'],
  ['PK2', 'bg-purple-400'],
  ['PK3', 'bg-purple-300'],
  ['PK4', 'bg-purple-200'],
  ['PK5', 'bg-purple-100'],
  ['FuturePK', 'bg-violet-400'],
  ['FuturePK1', 'bg-violet-400'],
  ['FuturePK2', 'bg-violet-300'],
  ['FuturePK3', 'bg-violet-200'],
  ['FuturePK4', 'bg-violet-100'],
  ['FuturePK5', 'bg-violet-100'],
])

const posColorsMap = new Map<string, iColorDefinition>([
  ['QB', { color: 'green', background: 'bg-green-500', border: 'border-green-600', shade: 'bg-green-600' }],
  ['RB', { color: 'red', background: 'bg-red-500', border: 'border-red-600', shade: 'bg-red-600' }],
  ['WR', { color: 'yellow', background: 'bg-yellow-500', border: 'border-yellow-600', shade: 'bg-yellow-600' }],
  ['TE', { color: 'blue', background: 'bg-blue-500', border: 'border-blue-600', shade: 'bg-blue-600' }],
  ['PK', { color: 'purple', background: 'bg-purple-500', border: 'border-purple-600', shade: 'bg-purple-600' }],
  ['PK1', { color: 'purple', background: 'bg-purple-500', border: 'border-purple-600', shade: 'bg-purple-600' }],
  ['PK2', { color: 'purple', background: 'bg-purple-400', border: 'border-purple-500', shade: 'bg-purple-500' }],
  ['PK3', { color: 'purple', background: 'bg-purple-300', border: 'border-purple-400', shade: 'bg-purple-400' }],
  ['PK4', { color: 'purple', background: 'bg-purple-200', border: 'border-purple-300', shade: 'bg-purple-300' }],
  ['PK5', { color: 'purple', background: 'bg-purple-100', border: 'border-purple-200', shade: 'bg-purple-200' }],
  ['FuturePK', { color: 'violet', background: 'bg-violet-400', border: 'border-violet-300', shade: 'bg-violet-300' }],
  ['FuturePK1', { color: 'violet', background: 'bg-violet-400', border: 'border-violet-300', shade: 'bg-violet-300' }],
  ['FuturePK2', { color: 'violet', background: 'bg-violet-300', border: 'border-violet-200', shade: 'bg-violet-200' }],
  ['FuturePK3', { color: 'violet', background: 'bg-violet-200', border: 'border-violet-100', shade: 'bg-violet-100' }],
  ['FuturePK4', { color: 'violet', background: 'bg-violet-100', border: 'border-violet-100', shade: 'bg-violet-100' }],
  ['FuturePK5', { color: 'violet', background: 'bg-violet-100', border: 'border-violet-100', shade: 'bg-violet-100' }],
  ['default', { color: 'gray', background: 'bg-gray-500', border: 'border-gray-500', shade: 'bg-gray-500/80' }],
])

const actionColorMap = new Map([
  ['overpricedMinus', 'bg-red-500'],
  ['overpriced', 'bg-orange-300'],
  ['hold', 'bg-yellow-500'],
  ['underpriced', 'bg-green-300'],
  ['underpricedPlus', 'bg-green-500'],
])

const posBgMap = new Map([
  ['unranked', 'bg-gray-500'],
  ['transcendent', 'bg-blue-500'],
  ['generational', 'bg-purple-500'],
  ['bpp', 'bg-green-500'],
  ['coinflip', 'bg-yellow-500'],
  ['longshot', 'bg-orange-300'],
  ['bust', 'bg-red-500'],
])

const clusterColorMap = new Map([
  ['allPro', 'bg-green-500'],
  ['allStar', 'bg-green-300'],
  ['rolePlayer', 'bg-yellow-500'],
  ['highVariance', 'bg-orange-300'],
  ['benchWarmer', 'bg-orange-300'],
  ['other', 'bg-red-500'],
])

const posBorderMap = new Map([
  ['QB', 'border-green-500'],
  ['RB', 'border-red-500'],
  ['WR', 'border-yellow-500'],
  ['TE', 'border-blue-500'],
  ['PK', 'border-purple-500'],
])

export function getColorShade(color: iColorName, shade: iColorShade) {
  return colors[color][shade]
}

export function useChartColorScale(length: number) {
  return Array.from({ length }, (v, i) => colorScale(i / (length - 1)).hex())
}

export function useChartColor(index: number) {
  return chartColors[index % chartColors.length]
}

export function useClusterColorClass(string: string): string {
  return clusterColorMap.get(string) || 'bg-gray-500'
}
export function usePosBgClass(string: string): string {
  return posBgMap.get(string) || 'bg-gray-500'
}

export function usePosBorderClass(position?: string): string {
  return position ? posBorderMap.get(position) || 'border-gray-500' : 'border-gray-500'
}
export function useActionColorClass(string: string): string {
  return actionColorMap.get(string) || 'bg-gray-500'
}

export function usePositionColorClass({ id_pos, id_pff, adp_adp }: iPlayer): string {
  if (id_pos && id_pos !== 'PK')
    return posColorMap.get(id_pos) || 'bg-gray-500'
  const round = Number(id_pff?.slice(5, 6))
  if (!round || round > 5)
    return 'bg-gray-500'
  return adp_adp ? 'bg-purple-500' : (posColorMap.get(`FuturePK${round}`) || posColorMap.get('FuturePK')!)
}

export function usePositionColorsClass({ id_pos, id_pff, adp_adp }: iPlayer): iColorDefinition {
  if (id_pos && id_pos !== 'PK')
    return posColorsMap.get(id_pos) || posColorsMap.get('default')!
  const round = Number(id_pff?.slice(5, 6))
  if (!round || round > 5)
    return posColorsMap.get('default')!
  return adp_adp ? posColorsMap.get('PK')! : (posColorsMap.get(`FuturePK${round}`) || posColorsMap.get('FuturePK'))!
}

interface ColorParts {
  prefix?: string
  color?: string
  shade: string
}

export function useColorParser(colorClass: string): ColorParts {
  const [prefix, color, shade] = colorClass.split('-')
  return { prefix, color, shade }
}
export function useColorClassShadeLookup(colorClass: string, desiredShade: string) {
  const { prefix, color } = useColorParser(colorClass)
  if (!color)
    return
  if (useShadeLookup(colorClass, desiredShade) === color)
    return
  return `${prefix}-${color}-${desiredShade}`
}

export function useColorLookup(colorValue: string): string | Record<string, string> {
  return (Object.prototype.hasOwnProperty.call(colors, colorValue)) ? colors[colorValue as keyof typeof colors] : colorValue
}

export function useColorShadeLookup(colorValue: string | Record<string, string>, desiredShade: string) {
  return (typeof colorValue === 'object') ? (colorValue[desiredShade] || colorValue[500]) : colorValue
}

export function useShadeLookup(colorClass: string, desiredShade: string) {
  const { color } = useColorParser(colorClass)
  if (!color)
    return
  return useColorShadeLookup(useColorLookup(color), desiredShade)
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = Number.parseInt(hex.slice(1, 3), 16)
  const g = Number.parseInt(hex.slice(3, 5), 16)
  const b = Number.parseInt(hex.slice(5, 7), 16)
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
}

export function useColorToRGB(colorClass: string) {
  const { shade } = useColorParser(colorClass)
  const hexColor = useShadeLookup(colorClass, shade)
  return hexColor ? hexToRGB(hexColor) : undefined
}

const colorRanges = [
  { limit: 20, color: 'red' },
  { limit: 40, color: 'orange' },
  { limit: 60, color: 'yellow' },
  { limit: 80, color: 'teal' },
  { limit: 100, color: 'emerald' },
] as const
export function getColorByPercentage(percent: number) {
  for (const range of colorRanges) {
    if (percent <= range.limit)
      return range.color
  }
  return 'primary'
}

export function genRGBColorfromHex(color: string): [number, number, number] {
  return chroma(color).rgb()
}

export function getTextColorFromBackground(backgroundColor: string): string {
  const luminance = chroma(backgroundColor).luminance()
  const threshold = 0.5
  return luminance > threshold ? 'black' : 'white'
}
