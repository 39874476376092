<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps<{
  error: NuxtError
}>()

// useColorMode().value = 'dark'
// useHead(() => ({
//   htmlAttrs: {
//     class: 'dark',
//   },
// }))
// TODO REVISAR EL DARK MODE
useSeoMeta({
  title: 'Page not found',
  description: 'We are sorry but this page could not be found.',
})
</script>

<template>
  <div class="relative bg-gradient-main">
    <navBar />
    <NuxtLayout name="default">
      <UPageError :error="error" />
    </NuxtLayout>
  </div>
</template>
