import { DateTime } from 'luxon'

type iDateInput = string | number | Date | DateTime

export function genDateStartSeason(): DateTime<true> {
  return DateTime.now().startOf('year')
}
export function genDateDraftDay(): DateTime<true> {
  const DraftDate = DateTime.fromObject({ year: DateTime.now().year, month: 3, day: 25 })
  return DraftDate.isValid ? DraftDate : DateTime.now().endOf('year')
}
export function genDateStartFootballSeason(): DateTime<true> {
  const StartSeasonDate = DateTime.fromObject({ year: DateTime.now().year, month: 9, day: 15 })
  return StartSeasonDate.isValid ? StartSeasonDate : DateTime.now().endOf('year')
}
export function genDateMinPicker(): DateTime<true> {
  const minPicker = DateTime.fromObject({ year: 2021, month: 1, day: 1 }).startOf('day')
  return minPicker.isValid ? minPicker : DateTime.now().startOf('year')
}
export function genDateMinPickerUd(): DateTime<true> {
  const minPicker = DateTime.fromObject({ year: 2024, month: 6, day: 20 }).startOf('day')
  return minPicker.isValid ? minPicker : DateTime.now().startOf('year')
}
export function genDateMinPickerDk(): DateTime<true> {
  const minPicker = DateTime.fromObject({ year: 2024, month: 7, day: 14 }).startOf('day')
  return minPicker.isValid ? minPicker : DateTime.now().startOf('year')
}

export function genDateLimitPicker(): DateTime<true> {
  const DraftDay = DateTime.fromObject({ year: DateTime.now().year, month: 3, day: 25 }).endOf('day')
  if (DateTime.now() < DraftDay)
    return DraftDay.isValid ? DraftDay : DateTime.now().endOf('year')
  const EndOfSeason = DateTime.fromObject({ year: DateTime.now().year, month: 9, day: 15 }).endOf('day')
  if (DateTime.now() < EndOfSeason)
    return EndOfSeason.isValid ? EndOfSeason : DateTime.now().endOf('year')
  return DateTime.now().endOf('year')
}

export function genJSDateRange(dateRange: iDateInput[]): [Date, Date] {
  const newDateRange = parseDateRangeType(dateRange)
  if (!newDateRange)
    throw new Error('Invalid date range')
  return [newDateRange[0].toJSDate(), newDateRange[1].toJSDate()]
}
export function genDateRangeEpoch(dateRange?: iDateInput[]): (number | undefined)[] {
  if (!dateRange || dateRange.length === 0)
    return [undefined, undefined]
  return dateRange.map(date => Math.round(genLuxonDateObject(date).toSeconds()))
}
export function genDateRangePreDraft(): [DateTime<true>, DateTime<true>] {
  return [genDateStartSeason(), genDateDraftDay().endOf('day')]
}
export function genDateRangePostDraft(): [DateTime<true>, DateTime<true>] {
  return [genDateDraftDay().startOf('day'), genDateStartFootballSeason().endOf('day')]
}
export function genDateRangeLastMonth(): [DateTime<true>, DateTime<true>] {
  return [DateTime.now().minus({ months: 1 }).startOf('day').plus({ minutes: 5 }), DateTime.now().endOf('day').minus({ minutes: 5 })]
}
export function genDateRangeLast2Weeks(): [DateTime<true>, DateTime<true>] {
  return [DateTime.now().minus({ weeks: 2 }).startOf('day'), DateTime.now().endOf('day')]
}

export function checkDateLastMonth(dateRange: iDateInput[]): boolean {
  if (dateRange.length < 2)
    return false
  return !!((genLuxonDateObject(dateRange[0]).minute === 5 && genLuxonDateObject(dateRange[1]).minute === 54))
}

export function isDateRangeMatch(currentRange: [DateTime, DateTime], presets: [DateTime, DateTime][]): boolean {
  return presets.some(preset =>
    currentRange[0].equals(preset[0]) && currentRange[1].equals(preset[1]),
  )
}

export function parseDateRangeType(dateRange: iDateInput | iDateInput[]): [DateTime<true>, DateTime<true>] | undefined {
  dateRange = Array.isArray(dateRange) ? dateRange : [dateRange]
  if (dateRange.length === 0)
    return
  if (dateRange.length === 1) {
    const rangeDate = genLuxonDateObject(dateRange[0])
    return (rangeDate.isValid) ? [rangeDate.startOf('day'), rangeDate.endOf('day')] : undefined
  }
  if (checkDateLastMonth(dateRange))
    return genDateRangeLastMonth()
  const startRange = genLuxonDateObject(dateRange[0]).startOf('day')
  const endRange = genLuxonDateObject(dateRange[1]).endOf('day')
  return startRange.isValid && endRange.isValid ? [startRange, endRange] : undefined
}

export function genLuxonDateObject(dateInput: iDateInput) {
  if (typeof dateInput === 'number')
    return genLuxonDateFromNumber(dateInput)

  if (dateInput instanceof Date)
    return genLuxonDateFromJSDate(dateInput)

  if (dateInput instanceof DateTime)
    return dateInput

  return genLuxonDateFromString(dateInput)
}
export function genLuxonDateFromJSDate(dateInput: Date): DateTime {
  return DateTime.fromJSDate(dateInput)
}
export function genLuxonDateFromString(dateInput: string): DateTime {
  const date = DateTime.fromISO(dateInput)
  return date.isValid ? date : DateTime.fromFormat(dateInput, 'yyyy-MM-dd HH:mm:ss')
}
export function genLuxonDateFromNumber(dateInput: number): DateTime {
  return DateTime.fromMillis(dateInput < 1e12 ? dateInput * 1000 : dateInput)
}

export function genDateRangeStringFormat([startDate, endDate]: DateTime<true>[]) {
  const currentYear = DateTime.now().year
  const includeYear = startDate.year !== currentYear || endDate.year !== currentYear
  return `LLL dd${includeYear ? ' yy' : ''}`
}

export function genDateRangeString(dateInput: number[]): string {
  const dateRange = dateInput.map(date => genLuxonDateObject(date))
  if (!dateRange.every(date => date.isValid))
    return 'Invalid date range'
  const format = genDateRangeStringFormat(dateRange)
  return dateRange.map(date => date.toFormat(format)).join(' - ')
}

export function genDateString(dateInput: iDateInput) {
  const dateObj = genLuxonDateObject(dateInput)
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: dateObj.hasSame(DateTime.now(), 'year') ? undefined : '2-digit',
  }
  return dateObj.toLocaleString(options)
}

// TODO IMPLEMENTAR ESTO
// genDateRangeString(dateInput) {
//   if (!dateInput || dateInput.length < 2)
//     this.#error('input', dateInput)
//   const [startDate, endDate] = dateInput.map(date => this.genObject(date))
//   if (!startDate || !endDate)
//     this.#error('time', dateInput)
//   const interval = Interval.fromDateTimes(startDate, endDate)
//   let dateString = interval.start.toFormat('MMM d')
//   if (interval.start.year !== this.currentYear)
//     dateString += `, ${interval.start.year}`
//   if (interval.start.month !== interval.end.month || interval.start.day !== interval.end.day) {
//     dateString += ` - ${interval.end.toFormat('MMM d')}`
//     if (interval.end.year !== this.currentYear)
//       dateString += `, ${interval.end.year}`
//   }
//   return dateString
// }

export function genArrayRangeDates(dateRange: iDateInput[]): DateTime[] {
  const [startDate, endDate] = dateRange.map(date => genLuxonDateObject(date))
  const dateArray: DateTime[] = []
  let currentDate = startDate.startOf('day')
  while (currentDate <= endDate) {
    dateArray.push(currentDate)
    currentDate = currentDate.plus({ days: 1 })
  }
  return dateArray
}
