<script setup lang="ts">
await callOnce(async () => {
  useSupabaseClient().auth.onAuthStateChange((event, session) => {
    if (['SIGNED_OUT', 'TOKEN_REFRESHED', 'SIGNED_IN', 'INITIAL_SESSION'].includes(event))
      useAuthStore().parseNewSession(session)
    if (['SIGNED_IN', 'INITIAL_SESSION'].includes(event) && session)
      useAuthStore().refreshUserProfile(session.user.id)
  })
  await usePlayerDataStore().refreshPlayerData()
})
</script>

<template>
  <NuxtPwaManifest />
  <div class="bg-gradient-main relative">
    <navBar />
    <NuxtLoadingIndicator />
    <div>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
  </div>
</template>

<style src="~/assets/css/main.css" />
